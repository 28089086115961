<template>
  <div>
    <legend>Representant legal de la <strong>{{ hostName }}</strong></legend>
    <div class="col-sm-6">
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Nom i cognoms</label>
        <input
          v-model="document.lr_name"
          type="text"
          class="form-control"
        >
      </div>
      <div class="mb-1">
        <label
          for=""
          class="form-label"
        >Càrrec</label>
        <input
          v-model="document.lr_position"
          type="text"
          class="form-control"
        >
      </div>
    </div>
    <template v-if="isAcceptanceDocument">
      <div class="form-check mb-2">
        <input
          id="inlineCheckbox1"
          v-model="user.not_fulfill_requeriments"
          class="form-check-input"
          type="checkbox"
          value="checked"
        >
        <label
          class="form-check-label"
          for="inlineCheckbox1"
        >En cas de no complir amb els requeriments prèviament mencionats, marqueu aquesta casella.</label>
      </div>
      <div class="form-check mb-2">
        <input
          id="inlineCheckbox2"
          v-model="user.not_authorized_image"
          class="form-check-input"
          type="checkbox"
        >
        <label
          class="form-check-label"
          for="inlineCheckbox2"
        >L’investigador/a premiat/da <strong>NO autoritza a ICREA</strong> a la publicació de la seva imatge i de les seves dades personals relacionades amb la seva condició com a premiat/da de la Convocatòria ICREA Acadèmia en curs, al seu lloc web <a
          href="https://www.icrea.cat"
          target="_blank"
          class="text-dark text-decoration-underline"
        >https://www.icrea.cat</a>, per tal de difondre i informar de la seva activitat. Per tant, si no vol que es publiquin les seves dades, marqui aquesta casella.</label>
      </div>
    </template>
    <template v-if="document.lr_name && document.lr_position">
      <hr>
      <h4>Document {{ !isAcceptanceDocument ? ' de justificació anual' : '' }}</h4>
      <p>Si us plau, descarregueu el document de justificació, signeu-lo i pugeu-lo aquí:</p>
      <div class="mb-1">
        <div
          class="input-group custom-file-button"
        >
          <label
            class="input-group-text"
            for="formFile"
          >Select file</label>
          <input
            :ref="'file-new'"
            type="file"
            class="form-control"
            @change="uploadFile"
          >
        </div>
        <div
          v-if="hasSignature.filter(e => e.name == 'justification' && e.validated === true).length > 0"
          class="p-1 mt-1 alert alert-success w-25 text-center"
        >
          Signature validated {{ loggedUser.roles.includes('super-admin') ? '- bypassed by admin role' : '' }}
        </div>
        <div
          v-if="hasSignature.filter(e => e.name == 'justification' && e.validated === false).length > 0"
          class="p-1 mt-1 alert alert-danger w-25 text-center"
        >
          Signature invalid
        </div>
        <div
          v-if="loadingSignature == 'justification'"
          class="loading custom-loading"
        >
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
      </div>
      <div class="d-flex">
        <p><span
          target="_blank"
          title="view document"
          class="btn bg-light-secondary btn-sm"
          @click="downloadDefualtFile"
        ><i data-feather="download" /> Descarregueu el document </span></p>
        <p v-if="document.document"><a
          :href="document.document.url"
          class="btn bg-light-secondary btn-sm ms-2"
          target="_blank"
        ><i data-feather="download" />  Veieu el document pujat prèviament ({{ document.document.name }})</a></p>
      </div>
      <template v-if="!isAcceptanceDocument">
        <h4>Memòria científica</h4>
        <p>Si us plau, descarregueu la plantilla de la memòria, ompliu-la i pugeu-la aquí:</p>
        <div class="mb-1">
          <div
            class="input-group custom-file-button"
          >
            <label
              class="input-group-text"
              for="formFile"
            >Select file</label>
            <input
              :ref="'file-scientific-memory'"
              type="file"
              class="form-control"
              @change="uploadScientificMemory"
            >
          </div>
          <div
            v-if="hasSignature.filter(e => e.name == 'scientific' && e.validated === true).length > 0"
            class="p-1 mt-1 alert alert-success w-25 text-center"
          >
            Signature validated {{ loggedUser.roles.includes('super-admin') ? '- bypassed by admin role' : '' }}
          </div>
          <div
            v-if="hasSignature.filter(e => e.name == 'scientific' && e.validated === false).length > 0"
            class="p-1 mt-1 alert alert-danger w-25 text-center"
          >
            Signature invalid
          </div>
          <div
            v-if="loadingSignature == 'scientific'"
            class="loading custom-loading"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
        </div>
        <p v-if="document.scientific_memory"><a
          :href="document.scientific_memory.url"
          class="btn bg-light-secondary btn-sm"
          target="_blank"
        ><i data-feather="download" />  Veieu la memòria científica pujada prèviament ({{ document.scientific_memory.name }})</a></p>
      </template>
      <template v-if="user.canViewEconomic || checkAdminEconomic">
        <hr>
        <h4>Memòria econòmica</h4>
        <p>Si us plau, descarregueu la plantilla de la memòria econòmica, ompliu-la i pugeu-la aquí:</p>
        <div class="mb-1">
          <div
            class="input-group custom-file-button"
          >
            <label
              class="input-group-text"
              for="formFile"
            >Select file</label>
            <input
              :ref="'file-economic-memory'"
              type="file"
              class="form-control"
              @change="uploadEconomicMemory"
            >
          </div>
        </div>
        <p v-if="user.economy_document"><a
          :href="user.economy_document.url"
          class="btn bg-light-secondary btn-sm"
          target="_blank"
        ><i data-feather="download" />  Veieu la memòria econòmica pujada prèviament ({{ user.economy_document.name }})</a></p>
        <h4>Resum memòria econòmica</h4>
        <p>Si us plau, imprimiu en format pdf la pestanya resum, signeu-la i pugeu-la aquí:</p>
        <div class="mb-1">
          <div
            class="input-group custom-file-button"
          >
            <label
              class="input-group-text"
              for="formFile"
            >Select file</label>
            <input
              :ref="'file-economic-memory-resum'"
              type="file"
              class="form-control"
              @change="uploadResumEconomicMemory"
            >
          </div>
          <div
            v-if="hasSignature.filter(e => e.name == 'economic_resum' && e.validated === true).length > 0"
            class="p-1 mt-1 alert alert-success w-25 text-center"
          >
            Signature validated {{ loggedUser.roles.includes('super-admin') ? '- bypassed by admin role' : '' }}
          </div>
          <div
            v-if="hasSignature.filter(e => e.name == 'economic_resum' && e.validated === false).length > 0"
            class="p-1 mt-1 alert alert-danger w-25 text-center"
          >
            Signature invalid
          </div>
          <div
            v-if="loadingSignature == 'economic_resum'"
            class="loading custom-loading"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
        </div>
        <p v-if="user.economy_document_resum"><a
          :href="user.economy_document_resum.url"
          class="btn bg-light-secondary btn-sm"
          target="_blank"
        ><i data-feather="download" />  Veieu el resum de la memòria econòmica pujada prèviament ({{ user.economy_document_resum.name }})</a></p>
      </template>
    </template>
    <template v-else>
      <div class="alert alert-warning p-1">
        Per pujar documentació has d'omplir els camps "Nom i cognoms" i "Càrrec"
      </div>
    </template>
    <hr>
    <div class="page-actions">
      <button
        v-show="!saving && !savingSubmit"
        id="type-success"
        class="btn btn-info me-1"
        @click="preSave(false)"
      >
        <i data-feather="save" /> <span>Guardar</span>
      </button>
      <button
        v-show="saving && !savingSubmit"
        class="btn btn-outline"
        type="button"
        disabled=""
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        <span class="ms-25 align-middle">Guardant...</span>
      </button>
      <template
        v-if="document.lr_name && document.lr_position && (hasAllSignatures && (((user.canViewEconomic || checkAdminEconomic) && (user.economy_document || memoirEconomic.name)) || !(user.canViewEconomic || checkAdminEconomic)))"
      >
        <button
          v-show="!savingSubmit && !saving"
          id="type-success"
          class="btn btn-success me-1"
          @click="preSave(true)"
        >
          <i data-feather="save" /> <span>Enviar</span>
        </button>
        <button
          v-show="savingSubmit && !saving"
          class="btn btn-outline-success"
          type="button"
          disabled=""
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          <span class="ms-25 align-middle">Enviant...</span>
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    hostName: {
      type: String,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    isAcceptanceDocument: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      nnfr: false,
      nai: false,
      file: {},
      scientificMemory: {},
      memoirEconomic: {},
      memoirEconomicResum: {},
      saving: false,
      savingSubmit: false,
      checkAdminEconomic: false,
      hasSignature: [],
      loadingSignature: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'academyAwarded/item',
      loggedUser: 'auth/admin',
    }),
    hasAllSignatures() {
      if (this.isAcceptanceDocument) {
        return this.hasSignature.filter(e => e.validated === true).length > 0
      }

      if (!this.isAcceptanceDocument && (!this.user.canViewEconomic && !this.checkAdminEconomic)) {
        return this.hasSignature.filter(e => e.validated === true).length > 1
      }

      if (!this.isAcceptanceDocument && (this.user.canViewEconomic || this.checkAdminEconomic)) {
        return this.hasSignature.filter(e => e.validated === true).length > 2
      }

      return false
    },
  },
  watch: {
    'document.id': function () {
      if (this.loggedUser.roles.includes('super-admin')) {
        if (this.user.documents[this.user.documents.length - 1].id === this.document.id) {
          this.checkAdminEconomic = true
        } else {
          this.checkAdminEconomic = false
        }
      }
    },
    'document.lr_name': function () {
      if (this.document.lr_name && this.document.lr_position) {
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
      }
    },
    'document.lr_position': function () {
      if (this.document.lr_name && this.document.lr_position) {
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
      }
    },
  },
  mounted() {
    if (this.loggedUser.roles.includes('super-admin')) {
      if (this.user.documents[this.user.documents.length - 1].id === this.document.id) {
        this.checkAdminEconomic = true
      } else {
        this.checkAdminEconomic = false
      }
    }

    const data = []
    if (this.document.document) {
      data.push({
        name: 'justification',
        validated: true,
      })
    }

    if (this.document.scientific_memory) {
      data.push({
        name: 'scientific',
        validated: true,
      })
    }

    /* if (this.user.economy_document) {
      data.push({
        name: 'economic',
        validated: true,
      })
    } */

    if (this.user.economy_document_resum) {
      data.push({
        name: 'economic_resum',
        validated: true,
      })
    }

    this.hasSignature = data

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    async preSave(button) {
      if (button) {
        Vue.swal({
          title: 'Segur que vols enviar el justificant?',
          html: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Si!',
        }).then(result => {
          if (result.isConfirmed) {
            this.save(button)
          }
        })
      } else {
        await this.save(button)
      }
    },
    async save(button) {
      this.saving = true

      try {
        const data = {
          lr_name: this.document.lr_name,
          lr_position: this.document.lr_position,
          not_fulfill_requeriments: this.user.not_fulfill_requeriments,
          not_authorized_image: this.user.not_authorized_image,
          file: this.file,
          file_scientific_memory: this.scientificMemory,
          file_economic_memoir: this.memoirEconomic,
          file_economic_memoir_resum: this.memoirEconomicResum,
          needPushEconomic: this.user.canViewEconomic || this.checkAdminEconomic,
          submit: button,
        }

        if (this.isAcceptanceDocument) {
          data.isAD = this.isAcceptanceDocument
        }

        await this.$store.dispatch('academyAwarded/signed', { documentId: this.document.id, data })
        Vue.prototype.$saveFiles([data.file, data.file_scientific_memory, data.file_economic_memoir, data.file_economic_memoir_resum])
      } catch (e) {
        console.log(e)
      }

      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)

      this.saving = false
    },
    cancel() {
      this.lr_name = ''
      this.lr_position = ''
    },
    uploadFile() {
      if (this.$refs['file-new']) {
        const file = this.$refs['file-new'].files[0]

        if (file) {
          this.file = {
            name: file.name,
            original_name: file.name,
            description: 'Document Signed',
            weight: (file.size / 1024 / 1024).toFixed(2),
            extension: file.type,
            created_at: 'Pending to save...',
            file,
            file_type: file.type,
          }

          this.checkIfSigned('justification', 'file-new')

          setTimeout(() => {
            feather.replace({
              width: 14,
              height: 14,
            })
          }, 200)
        }
      }
    },
    checkIfSigned(name, document) {
      const file = this.$refs[document].files[0]

      const formData = new FormData()
      formData.append('file', file)
      const outside = this

      this.loadingSignature = name

      Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/files/check-signed`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(e => {
        let data = {}
        if (e.status == 200 && Object.keys(e.data).length > 1) {
          data = {
            name,
            validated: true,
          }
        } else {
          data = {
            name,
            validated: false,
          }
        }

        if (outside.hasSignature.findIndex(x => x.name === data.name) !== -1) {
          outside.hasSignature.splice(outside.hasSignature.findIndex(x => x.name === data.name), 1, data)
        } else {
          outside.hasSignature.push(data)
        }

        outside.loadingSignature = null
      })
    },
    uploadScientificMemory() {
      if (this.$refs['file-scientific-memory']) {
        const file = this.$refs['file-scientific-memory'].files[0]

        if (file) {
          this.scientificMemory = {
            name: file.name,
            original_name: file.name,
            description: 'Scientific memoir',
            weight: (file.size / 1024 / 1024).toFixed(2),
            extension: file.type,
            created_at: 'Pending to save...',
            type: 10,
            file,
            file_type: file.type,
          }

          this.checkIfSigned('scientific', 'file-scientific-memory')

          setTimeout(() => {
            feather.replace({
              width: 14,
              height: 14,
            })
          }, 200)
        }
      } else {
        console.log('UPLOAD FILE ERROR')
      }
    },
    uploadEconomicMemory() {
      if (this.$refs['file-economic-memory']) {
        const file = this.$refs['file-economic-memory'].files[0]

        if (file) {
          this.memoirEconomic = {
            name: file.name,
            original_name: file.name,
            description: 'Economic memoir',
            weight: (file.size / 1024 / 1024).toFixed(2),
            extension: file.type,
            created_at: 'Pending to save...',
            type: 10,
            file,
            file_type: file.type,
          }

          // this.checkIfSigned('economic', 'file-economic-memory')

          setTimeout(() => {
            feather.replace({
              width: 14,
              height: 14,
            })
          }, 200)
        }
      } else {
        console.log('UPLOAD FILE ERROR')
      }
    },
    uploadResumEconomicMemory() {
      if (this.$refs['file-economic-memory-resum']) {
        const file = this.$refs['file-economic-memory-resum'].files[0]

        if (file) {
          this.memoirEconomicResum = {
            name: file.name,
            original_name: file.name,
            description: 'Economic memoir resum',
            weight: (file.size / 1024 / 1024).toFixed(2),
            extension: file.type,
            created_at: 'Pending to save...',
            type: 10,
            file,
            file_type: file.type,
          }

          this.checkIfSigned('economic_resum', 'file-economic-memory-resum')

          setTimeout(() => {
            feather.replace({
              width: 14,
              height: 14,
            })
          }, 200)
        }
      } else {
        console.log('UPLOAD FILE ERROR')
      }
    },
    downloadDefualtFile() {
      if (this.document.is_acceptance_document) {
        const data = `lr_name=${this.document.lr_name}&lr_position=${this.document.lr_position}&not_fulfill_requeriments=${this.user.not_fulfill_requeriments}&not_authorized_image=${this.user.not_authorized_image}`
        Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/academy-awarded/document/${this.document.id}/generateDocument?${data}`, {
          responseType: 'blob',
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const a = document.createElement('a')
          a.href = url
          a.download = 'pdf-acceptance.pdf'
          a.click()
          window.URL.revokeObjectURL(url)
        })
      } else {
        const data = `lr_name=${this.document.lr_name}&lr_position=${this.document.lr_position}`
        Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/academy-awarded/document/${this.document.id}/generateJustificationDocument?${data}`, {
          responseType: 'blob',
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const a = document.createElement('a')
          a.href = url
          a.download = 'pdf-justification.pdf'
          a.click()
          window.URL.revokeObjectURL(url)
        })
      }
    },
  },
}

</script>
